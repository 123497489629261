//https://medium.com/@gabrielmickey28/using-debounce-with-react-components-f988c28f52c1
import moment from "moment";
import momentTimezone from "moment-timezone";
import { toast } from "react-toastify";
import i18n from 'i18next';
import store from "../store";

export const GuidEmpty = '00000000-0000-0000-0000-000000000000';

export function printComponentPath(componentPath, props) {
  if (window.location.hostname === "localhost") {
    console.log("Component rendered: ", componentPath, props);
  }
}
export function formatSalutation(salutation) {
  if (!salutation || salutation === " ") return "";
  const capitalized =
    salutation.charAt(0).toUpperCase() + salutation.slice(1).toLowerCase();
  return capitalized.endsWith(".") ? capitalized : `${capitalized}.`;
}
export function formatPassengerName(passengerDetails) {
  return `${formatSalutation(passengerDetails.salutation ?? "")} ${
    passengerDetails.firstName ?? ""
  } ${passengerDetails.lastName ?? ""}`.trim();
}
export const Currencies = [
  // North America
  { id: 'USD', symbol: "$", label: "USD - $" },
  { id: 'CAD', symbol: "$", label: "CAD - $" },
  { id: 'MXN', symbol: "Mex$", label: "MXN - $" },

  // Europe
  { id: 'EUR', symbol: "€", label: "EUR - €" },
  { id: 'GBP', symbol: "£", label: "GBP - £" },
  { id: 'CHF', label: "CHF" },
  { id: 'PLN', symbol: "zł", label: "PLN - zł" },
  { id: 'TRY', symbol: "₺", label: "TRY - ₺" },
  { id: 'SEK', symbol: "kr", label: "SEK - kr" },
  { id: 'NOK', symbol: "kr", label: "NOK - kr" },
  { id: 'DKK', symbol: "kr", label: "DKK - kr" },

  // Africa
  { id: 'EGP', symbol: "£", label: "EGP - £" },
  { id: 'MAD', symbol: "MAD", label: "MAD - Moroccan Dirham" },
  { id: 'AED', symbol: "AED", label: "AED - د.إ" },
  { id: 'SAR', symbol: "﷼", label: "SAR - ﷼" },
  { id: 'ZAR', symbol: "R", label: "ZAR - Rand" },

  // Latin America
  { id: 'DOP', symbol: "RD$", label: "DOP - Dominican peso" },
  { id: 'BRL', symbol: "R$", label: "BRL - R$" },
  { id: 'ARS', symbol: "$", label: "ARS - $" },
  { id: 'CLP', symbol: "$", label: "CLP - $" },

  // Asia
  { id: 'CNY', symbol: "¥", label: "CNY - Chinese Yuan ¥" },
  { id: 'JPY', symbol: "¥", label: "JPY - ¥ (Japanese Yen)" },
  { id: 'INR', symbol: "₹", label: "INR - ₹" },
  { id: 'THB', symbol: "฿", label: "THB - ฿" },
  { id: 'VND', symbol: "₫", label: "VND - ₫" },
  { id: 'FJD', symbol: "FJ$", label: "FJD - Fijian dollar" },
  { id: 'PHP', symbol: "₱", label: "PHP - Philippine peso" },
  { id: 'MYR', symbol: "RM", label: "MYR - RM (Malaysia ringgit)" },
  { id: 'IDR', symbol: "Rp", label: "IDR - Rp (Indonesian rupiah)" },
  { id: 'BDT', symbol: "৳", label: "BDT - ৳ (Bangladesh)" },
  { id: 'KRW', symbol: "₩", label: "KRW - ₩ (South Korean Won)" },

  // Oceania
  { id: 'AUD', symbol: "$", label: "AUD - $" },
  { id: 'NZD', symbol: "$", label: "NZD - $" },
  { id: 'SGD', symbol: "$", label: "SGD - $" },
];

//https://www.educative.io/edpresso/how-to-use-the-debounce-function-in-javascript
export function debounce(func, wait, immediate) {
    var timeout;
  
    return function executedFunction() {
      var context = this;
      var args = arguments;
          
      var later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
  
      var callNow = immediate && !timeout;
      
      clearTimeout(timeout);
  
      timeout = setTimeout(later, wait);
      
      if (callNow) func.apply(context, args);
    };
  };

//https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
export function safeRound(numInput, numPrecision = 2) {
  if(!numInput) numInput = 0;
  return numInput.toFixed(numPrecision);
  // const strNumber = numInput.toString().replace( 'E', 'e' );
  // const bSign = '+-'.indexOf( strNumber[ 0 ] ) !== -1;
  // const strSign = bSign  ?  strNumber[ 0 ]  :  '';
  // const numSign = strSign !== '-'  ?  +1  :  -1;
  // const ixExponent = ( ixFound => ixFound !== -1  ?  ixFound  :  strNumber.length )( strNumber.indexOf( 'e' ) );
  // const strExponent = strNumber.substr( ixExponent + 1 );
  // const numExponent = ixExponent !== strNumber.length  ?  Number.parseInt( strExponent )  :  0;
  // const ixDecimal = ( ixFound => ixFound !== -1  ?  ixFound  :  ixExponent )( strNumber.indexOf( '.' ) );
  // const strInteger = strNumber.substring( !bSign  ?  0  :  1, ixDecimal );
  // const strFraction = strNumber.substring( ixDecimal + 1, ixExponent );

  // const numPrecisionAdjusted = numPrecision + numExponent;
  // const strIntegerKeep = strInteger.substring( 0, strInteger.length + Math.min( 0, numPrecisionAdjusted ) ) + '0'.repeat( -Math.min( 0, numPrecisionAdjusted ) );
  // const strFractionKeep = strFraction.substring( 0, Math.max( 0, numPrecisionAdjusted ) );
  // const strRoundedDown = strSign + ( strIntegerKeep === ''  ?  '0'  :  strIntegerKeep ) + ( strFractionKeep === ''  ?  ''  :  '.' + strFractionKeep ) + ( strExponent === ''  ?  ''  :  'e' + strExponent );

  // const chRoundUp = 0 <= numPrecisionAdjusted  ?  strFraction.substr( numPrecisionAdjusted, 1 )  :  ( '0' + strInteger ).substr( numPrecisionAdjusted, 1 );
  // const bRoundUp = '5' <= chRoundUp && chRoundUp <= '9';
  // const numRoundUp = bRoundUp  ?  numSign * Math.pow( 10, -numPrecision )  :  0;

  // return Number.parseFloat( strRoundedDown ) + numRoundUp;
}

export function safeAdd(valueA, valueB){
  if(!valueA) valueA = 0;
  if(!valueB) valueB = 0;
  return valueA + valueB;
}

export function prettyPrint(value, prefix, suffix) {
  if (!value) value = '-';
  return value.toString();
}

export function getMomentTimezone(jsDateObject, timezone = "Etc/UTC") {
  if (!jsDateObject) return null;

  const dateString = `${padStart(jsDateObject.getDate(), 2, "0")}-${padStart((jsDateObject.getMonth() + 1), 2, "0")}-${jsDateObject.getFullYear()} ${padStart(jsDateObject.getHours(), 2, "0")}:${padStart(jsDateObject.getMinutes(), 2, "0")}`;
  return momentTimezone.tz(dateString, "DD-MM-YYYY hh:mm", timezone);
}

/**
 * @param {Date} jsDateObject
 */
export function getMomentUtc(jsDateObject, timezone = "utc") {
  if (!jsDateObject) return null;
  const dateString = `${padStart(jsDateObject.getDate(), 2, "0")}-${padStart((jsDateObject.getMonth() + 1), 2, "0")}-${jsDateObject.getFullYear()} ${padStart(jsDateObject.getHours(), 2, "0")}:${padStart(jsDateObject.getMinutes(), 2, "0")}`;
  return moment.utc(dateString, "DD-MM-YYYY hh:mm");
}

/**
 * @param {String} sateISOString
 */
export function parseISOStringToMoment(sateISOString, timezone = "utc") {
  //console.log("parseStringToMoment", sateISOString, timezone);
  if (!sateISOString) return null;

  var parsed = moment(sateISOString);
  //console.log("parsed", parsed);
  if(timezone != "utc"){
    var inTimezone = parsed.tz(timezone);
    //console.log("inTimezone", inTimezone.format("YYYY-MM-DDTHH:mm:ss"));
    return moment(inTimezone.format("YYYY-MM-DDTHH:mm:ss"));
  }
}

/**
 * @param {Date} jsDateObject
 */
export function getMoment(jsDateObject, timezone = "utc") {
  if (!jsDateObject) return null;
  if (timezone === "utc") {
    const dateString = `${padStart(jsDateObject.getDate(), 2, "0")}-${padStart((jsDateObject.getMonth() + 1), 2, "0")}-${jsDateObject.getFullYear()} ${padStart(jsDateObject.getHours(), 2, "0")}:${padStart(jsDateObject.getMinutes(), 2, "0")}`;
    return moment.utc(dateString, "DD-MM-YYYY hh:mm");
  }
  else if(timezone === "local"){
    return moment(jsDateObject);
  }
  //convert datetime to specified timezone using moment-timezone
  else {
    return momentTimezone.tz(jsDateObject, timezone);
  }

  //convert java date to specified timezone using moment-timezone
  // const dateString = `${padStart(jsDateObject.getDate(), 2, "0")}-${padStart((jsDateObject.getMonth() + 1), 2, "0")}-${jsDateObject.getFullYear()} ${padStart(jsDateObject.getHours(), 2, "0")}:${padStart(jsDateObject.getMinutes(), 2, "0")}`;
  // return momentTimezone.tz(dateString, "DD-MM-YYYY hh:mm", timezone);
}

export function padStart(stringOrNumber, targetLength, padStringOrNumber) {
  return stringOrNumber.toString().padStart(targetLength, padStringOrNumber);
}

export function padEnd(stringOrNumber, targetLength, padStringOrNumber) {
  return stringOrNumber.padEnd(targetLength, padStringOrNumber);
}

/**
 * @param {any} value
 */
export function isObject(value) {
  //return !(value instanceof Date) && !Array.isArray(value) && !Object.is(value, null) && !Object.is(value, undefined) && !(value instanceof Function)
  return typeof value == 'object' && value instanceof Object && !(value instanceof Array)
}

export function objectContains(object, valueString) {
  const values = Object.values(object);
  const searchTextSplit = valueString.toLowerCase().trim().split(" ");

  return searchTextSplit.every(searchTerm => 
    values.some(v => {
      if (Array.isArray(v)) {
        return v.some(element => objectContains(element, searchTerm));
      }
      if (isObject(v)) {
        return objectContains(v, searchTerm);
      } else {
        if (v === null || v === undefined) {
          return false;
        }
        return v.toString().toLowerCase().includes(searchTerm);
      }
    })
  );
}

/**
 * @param {string} searchFilter
 * @param {any[]} itemsToFilter
 */
export function filterItems(searchFilter, itemsToFilter) {
  if (searchFilter) {
    var searchTextTrimmed = searchFilter.toLowerCase().trim();
    return itemsToFilter.filter((x) => {
      return objectContains(x, searchTextTrimmed);
    });
  }
  return itemsToFilter;
}

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};
export function safeToJson(obj) {
  return JSON.stringify(obj, getCircularReplacer());
}

export function printJsDateTime(utcDate, timezone, format = "ddd, DD MMM @ hh:mm A") {
  if(!utcDate) return null;
  // Parse the UTC date using Moment.js
  const utcMoment = momentTimezone.utc(utcDate);

  // Convert to the user's local timezone
  const localMoment = utcMoment.tz(timezone);
  return localMoment.format(format);
}

/**
 * @param {moment.Moment} date
 */
export function printDateTime(date, format = "ddd, DD MMM · hh:mm A") {
  //console.log("printDateTime", date, format);
  var userState = store.getState().user;
  const hasDateTimeFormat = userState.dateFormat && userState.timeFormat;
  return date.format(hasDateTimeFormat ? userState.dateFormat + " · " + userState.timeFormat : format);
}
export function printDate(date, format = "ddd, DD MMM yyyy") {
  //console.log("printDateTime", date, format);
  return date.format(format);
}

export function printTimeFromMoment(momentDate, format = "hh:mm A") {
  //console.log("printDateTime", date, format);
  return momentDate.format(format);
}

/**
 * 
 * @param {import("moment").Moment} momentA 
 * @param {import("moment").Moment} momentB 
 * @returns String display of time between two dates in format "d h m s"
 */
export function printTimeAsDaysHoursBetweenTwoMoments(momentA, momentB) {
  if (momentA.isBefore(momentB)) {
    const temp = momentA;
    momentA = momentB;
    momentB = temp;
  }
  const duration = moment.duration(momentA.diff(momentB));
  const days = duration.days();
  const hours = duration.hours();
  let result = "";
  if (days > 0) {
    const dayKey = days > 1 ? 'common:timeUnits.days' : 'common:timeUnits.day';
    result += `${days} ${i18n.t(dayKey)} `;
  }
  if (hours > 0) {
    const hourKey = hours > 1 ? 'common:timeUnits.hours' : 'common:timeUnits.hour';
    result += `${hours} ${i18n.t(hourKey)}`;
  }
  // if (minutes > 0) {
  //   result += minutes + "m ";
  // }
  // if (seconds > 0) {
  //   result += seconds + "s ";
  // }
  return result.trim();
}

/**
 * @param {number} durationInMinutes
 */
export function printDurationAsHoursMinutes(durationInMinutes) {
  const hours = Math.floor(durationInMinutes / 60);
  const minutes = durationInMinutes % 60;
  let result = "";
  if (hours > 0) {
    const hourKey = hours > 1 ? 'common:timeUnits.hours' : 'common:timeUnits.hour';
    result += `${hours} ${i18n.t(hourKey)} `;
  }
  if (minutes > 0) {
    const minuteKey = minutes > 1 ? 'common:timeUnits.mins' : 'common:timeUnits.min';
    result += `${minutes} ${i18n.t(minuteKey)}`;
  }
  return result.trim();
}

/**
 * 
 * @param {number} distance in mters
 * @param {"km" | "mi"} unit to display
 * @returns String display of distance in specifyed unit
 */
export function printDistanceUsingMeters(distanceInMeters, unit) {
  if (unit === 'km') {
    return `${safeRound(distanceInMeters / 1000, 2)} ${i18n.t('distanceUnits.km')}`;
  }
  else {
    return `${safeRound(distanceInMeters / 1000 * 0.621371, 2)} ${i18n.t('distanceUnits.mi')}`;
  }
}

export function printDistance(distanceInKm, unit) {
  if (unit === 'mi') {
    return `${safeRound(distanceInKm * 0.621371, 2)} ${i18n.t('common:distanceUnits.mi')}`;
  }
  else {
    return `${safeRound(distanceInKm, 2)} ${i18n.t('common:distanceUnits.km')}`;
  }
}

export function swapArrayElements(arr, from, to) {
  arr.splice(from, 1, arr.splice(to, 1, arr[from])[0]);
}

export function hasRole(websiteWithRoles, roleName) {
  if(!websiteWithRoles?.roles) return false;
  return websiteWithRoles.roles.find(r => r === roleName) != null;
}

export function hasAnyRole(websiteWithRoles, roleNames) {
  if(!websiteWithRoles?.roles) return false;
  for (const roleName of roleNames) {
    const hasRole = websiteWithRoles.roles.find(r => r === roleName) != null;
    if (hasRole) return true;
  }
  return false;
}

export function getWhatsAppLink(phoneNumber, message) {
  if (message)
    return `https://wa.me/${phoneNumber}?text=${message}`;
  else
    return `https://wa.me/${phoneNumber}`;
}

/**
 * @param {...string} var_strings Strings to search for
 * @return {boolean} true if ANY of the arguments is contained in the string
 */
 String.prototype.containsIgnoreCase = function(var_strings) {
  const thisLowerCase = this.toLowerCase()
  for (let i = 0; i < arguments.length; i++) {
    let needle = arguments[i]
    if (thisLowerCase.indexOf(needle.toLowerCase()) >= 0) {
      return true
    }
  }
  return false
}

/**
 * 
 * @param {import("../api/ExternalApi").ApiResponse} Api response containing both errors or success messages  
 * @returns void
 */
export function showToastSuccessAndErrorMessagesFromApiResponse(apiResponse) {
  var successMessagesArray = null;
  var errorMessagesArray = null;
  if (apiResponse.successMessages) {
    successMessagesArray = apiResponse.successMessages;
  }
  if (apiResponse.errorMessages && apiResponse.errorMessages.length > 0) {
    errorMessagesArray = apiResponse.errorMessages.map(x => x.message);
  }
  if (!errorMessagesArray && apiResponse.errors && apiResponse.errors.length > 0) {
    errorMessagesArray = apiResponse.errors;
  }

  if (successMessagesArray) {
    showToastMessages(successMessagesArray, "success");
  }

  if (errorMessagesArray) {
    showToastMessages(errorMessagesArray, "error");
  }
}


/**
 * @param {import("../api/ExternalApi").WebApiResponse} apiResponse
 * @param {'success' | 'warning' | 'error'} type
 * @returns void
 */
export function showToastMessagesFromApiResponse(apiResponse) {
  var messagesArray = null;
  var messageClass = "success";
  if (apiResponse.success) {
    messagesArray = apiResponse.successMessages;
    messageClass = "success";
  }
  else {
    messagesArray = apiResponse.errorMessages;
    if (!messagesArray || messagesArray.length === 0) {
      messagesArray = apiResponse.errors.map(e => { return { message: e }; });
    }
    messageClass = "error";
  }

  var messages = messagesArray.map(x => x.message);
  console.log("showToastMessagesFromApiResponse", messages);
  showToastMessages(messages, messageClass);
}

/**
 * 
 * @param {string | string[]} messages 
 * @returns void
 */
export function showToastErrorMessages(messages) {
  showToastMessages(messages, "error");
}

/**
 * 
 * @param {string | string[]} messages 
 * @param {'success' | 'warning' | 'error'} type 
 * @returns void
 */
export function showToastMessages(messages, type = "error") {
  if (typeof messages === 'string') {
    showToastMessages([messages], type);
  } else {

    if (messages.length === 0) return;

    var message = messages.length > 1
      ? messages.join("<br/>")
      : messages[0];
    toast(message, {
      type: type,
      position: 'top-right',
      hideProgressBar: false,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
    });
  }
}

export function getUtcDate(date) {
  return moment(date).utcOffset(0, true).toDate();
}

/**
 * Phone picker:
 *   - does not put + in front of the number
 *   - +1 is default number when phone is empty ->  null
 * @param {string} phoneNumber
 */
export function normalizePhoneNumber(phoneNumber, isInput = false) {
  //console.log("normalizePhoneNumber", phoneNumber);
  if (!phoneNumber) return null;
  //+1 is default number when phone is empty ->  null
  if (!isInput && phoneNumber.length < 5) return null;

  if (!phoneNumber.startsWith("+")) {
    phoneNumber = "+" + phoneNumber;
  }

  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(new RegExp("^[+]+"), "+");
  }

  //console.log("normalizedPhoneNumber returned", phoneNumber);
  return phoneNumber;
}

export function getGoogleMapLink(requestInfo) {
  var from = encodeURIComponent(requestInfo.from.addressName);
  var to = encodeURIComponent(requestInfo.to.addressName);
  // return `https://www.google.com/maps/dir/${from}/${to}`;
  return `https://www.google.com/maps/dir/?api=1&origin=${from}&destination=${to}&travelmode=driving`;
}

export function safeStringify(obj) {
  const seen = new WeakSet();
  return JSON.stringify(obj, (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  });
}